<template lang="pug">
  div(v-if='Blog')
    v-card(v-if='desde_componente' color='negro' max-width='1200' @click='dirigir_a({ruta: "entrada", slug: entrada.slug}, false)')
      v-hover(v-slot="{ hover }") 
        v-img(:width='primer_elemento ? 807 : 542' :height='primer_elemento ? 477 : 225'
              :src='entrada.portada'
              :gradient='hover ? "to bottom right, transparent , rgba(180, 50, 36, 0.45)" : "to bottom right, rgba(0, 0, 02 ,.5) , rgba(180, 50, 36, 0.45)"'
              style='border-radius: 0%;')
          div(v-if='primer_elemento')
            v-row.mt-12
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-8 
              v-container
                v-row.ml-2
                  div.rectangulo_componente.secundario.mr-2.ml-9.mt-1
                  h1(v-html='resumen_título' style='line-height : 35px;').font-weight-medium.white--text.mt-1
                v-row.ml-4
                  h2(style='line-height : 20px;').font-weight-light.white--text.ml-12.mt-4 {{formato_fecha}}
          div(v-else)
            v-row.mt-12
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12 
              v-container
                v-row
                  div.rectangulo_miniatura.secundario.mr-1.ml-7
                  span(v-html='resumen_título' style='line-height : 20px;').font-weight-medium.white--text.mt-1
                v-row
                  h6(style='line-height : 20px;').font-weight-light.white--text.ml-9.mt-2 {{formato_fecha}}
    v-card(v-else max-width='1200' @click='dirigir_a({ruta: "entrada", slug: entrada.slug}, false)')
      v-hover(v-slot="{ hover }") 
        v-img(:width='432' :height='305'
              :src='entrada.portada'
              :gradient='hover ? "to bottom right, transparent , rgba(180, 50, 36, 0.45)" : "to bottom right, rgba(0, 0, 02 ,.5) , rgba(180, 50, 36, 0.45)"'
              style='border-radius: 0%;')
          div
            v-row.mt-12
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12
            v-row.mt-10
              v-container
                v-row.ml-2
                  div.rectangulo_blog.secundario.mr-2.ml-6.mt-1
                  h2(v-html='resumen_título' style='line-height : 25px;').font-weight-medium.white--text.mt-1
                v-row.ml-4
                  h4.font-weight-light.white--text.ml-9.mt-3 {{formato_fecha}}
    //-v-card(v-else elevation=0 max-width='500')
      
      v-card-title
        v-row.justify-center
          //-Cuadro de día
          //-PC
          v-col(cols=2).pl-2.pr-0.mr-2.d-none.d-md-block
            v-img(:src='Blog.cuadro_blog' :height='65' )
              v-container.pa-0
                h3(style='line-height : 40px').negro--text.text-center.font-weight-light {{entrada.creado_el|fecha_a_día_letra}}
                p(style='line-height : 6px').negro--text.text-center {{entrada.creado_el|fecha_a_mes_letra}}
          //-Móvil
          v-col(cols=2).pl-0.pr-0.mr-2.d-md-none
            v-img(:src='Blog.cuadro_blog' :height='65' contain).pa-0
              v-container.pa-0
                h3(style='line-height : 40px').mr-2.negro--text.text-center {{entrada.creado_el|fecha_a_día_letra}}
                p(style='line-height : 6px').negro--text.text-center {{entrada.creado_el|fecha_a_mes_letra}}
          
          //-Título y subtitulo
          //-pc
          v-col.mt-3.d-none.d-md-block
            v-row
              strong(style='line-height : 8px').negro--text  {{entrada.título|resumen_título_o_subtítulo}}
            v-row    
              p.negro--text.mt-1  {{entrada.subtítulo|resumen_título_o_subtítulo}}
          //-movil
          v-col.mt-2.d-md-none
            v-row
              h5.negro--text.font-weight.bold(style='line-height : 20px')  {{entrada.título|resumen_título_o_subtítulo}}
            v-row
              p.negro--text.body-1   {{entrada.subtítulo|resumen_título_o_subtítulo}}
          
      //-Imagen, contenido y botón
      v-card-text.enlace
        v-img(:src='entrada.portada' :height='210' :width='367' )
        p(v-html='resumen').text-justify.negro--text.mb-1.mt-3
        v-container(fluid)
          v-row
            div(v-for='{nombre, id}, i in entrada.etiquetas' :key='i').eliminar_subrayado
              v-hover(v-slot="{ hover }")
                div
                  strong(v-if='hover' @click='dirigir_a({ruta: "blog", id}, true)').mr-1.negro--text {{(i!=entrada.etiquetas.length-1) ? `${nombre}, ` : `${nombre}.`}} 
                  strong(v-else :key='i').mr-1.secundario--text {{(i!=entrada.etiquetas.length-1) ? `${nombre}, ` : `${nombre}.`}}
        v-hover(v-slot="{ hover }").mt-2 
          v-btn(:color='hover ? "secundario" : "primario"' @click='dirigir_a({ruta: "entrada", slug: entrada.slug}, false)'
             style='border-radius:0px' block).forma_boton.elevation-0
            small(v-if='hover').white--text Leer más
            small(v-else).black--text Leer más
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  computed: {
    resumen(){
      return (this.entrada.contenido.length>250) ? `${this.entrada.contenido.substring(0, 250)}...` : this.entrada.contenido
    },
    resumen_título(){
      return `${this.entrada.título.substring(0, 25)}<br/>${this.entrada.título.substring(26, 45)}...`
    },
    formato_fecha(){
      let día = (this.entrada.creado_el.getDate() > 9) ? this.entrada.creado_el.getDate() : `0${this.entrada.creado_el.getDate()}`
      let mes = (this.entrada.creado_el.getMonth() > 9) ? this.entrada.creado_el.getMonth()+1 : `0${this.entrada.creado_el.getMonth()+1}`
      return `${día}/${mes}`
    },
  },
  props: {
    desde_componente: Boolean,
    entrada: Object,
    primer_elemento: Boolean,
  },
  methods: {
    dirigir_a({ruta, id}, es_etiqueta){
      if(es_etiqueta){
        this.alterar_etiqueta(id)
        this.$router.replace({name: ruta, params: {}})
        this.$vuetify.goTo("#navegador")
      } else {
        this.$router.replace({name: ruta, params: {slug: this.entrada.slug, id_entrada: this.entrada.id}})
        this.$vuetify.goTo("#navegador")
      }
    },
    ...mapMutations({
        alterar_etiqueta: 'Etiquetas/alterar_etiqueta',
    }),
  },
}
</script>
<style lang="sass">
  .eliminar_subrayado
    text-decoration: none!important
  .rectangulo_miniatura
    width: 7px
    height: 40px
  .rectangulo_componente
    width: 13px
    height: 75px
  .rectangulo_blog
    width: 10px
    height: 50px
</style>